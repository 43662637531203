import { StateCreator } from "zustand";
import get from "lodash/get";
import { storage } from "services";

export type TTheme = "dark" | "light";
// export type TSide = "left" | "right" | "top" | "bottom";

export interface ISystemInitialState {
  lang: string
  theme: TTheme
  layoutSide: string | null
  layoutColor: string | null
  layoutPattern: string | null
  toggleSidebar: string | null
}

export const SystemInitialState: ISystemInitialState = {
  lang: storage.get("i18nextLng") || "uz",
  theme: "light",
  layoutSide: storage.get("layoutSide") ? storage.get("layoutSide") : "left",
  layoutColor: storage.get("layoutColor") ? storage.get("layoutColor") : "black",
  layoutPattern: storage.get("layoutPattern") ? storage.get("layoutPattern") : "pattern1",
  toggleSidebar: storage.get("toggleSidebar") ? storage.get("toggleSidebar") : "false"
};

export interface ISystem {
  system: ISystemInitialState;
  setLang: (action: { [key: string]: any }) => void;
  changeTheme: (data: string) => any
  changeLayoutSide: (data: string) => any
  changeLayoutColor: (data: string) => any
  changePattern: (data: string) => any
  toggleSidebar: (data: string) => any
}

export const systemSlice: StateCreator<ISystem, [], []> = (set): ISystem => {
  return {
    system: SystemInitialState,
    setLang: (action: { [key: string]: any }) => {
      return set((state) => {
        return {
          system: {
            ...get(state, "system"),
            lang: "ru",
          },
        };
      });
    },
    changeTheme: (action: string) => {
      return set((state: any) => {
        return {
          system: {
            ...get(state, 'system'),
            theme: action,
          },
        }
      })
    },
    changeLayoutSide: (action: string) => {
      return set((state: any) => {
        storage.set("layoutSide", action);
        return {
          system: {
            ...get(state, 'system'),
            layoutSide: action,
          },
        }
      })
    },
    changeLayoutColor: (action: string) => {
      return set((state: any) => {
        storage.set("layoutColor", action);
        return {
          system: {
            ...get(state, 'system'),
            layoutColor: action,
          },
        }
      })
    },
    changePattern: (action: string) => {
      return set((state: any) => {
        storage.set("layoutPattern", action);
        return {
          system: {
            ...get(state, 'system'),
            layoutPattern: action,
          },
        }
      })
    },
    toggleSidebar: (action: string) => {
      return set((state: any) => {
        storage.set("toggleSidebar", action);
        return {
          system: {
            ...get(state, 'system'),
            toggleSidebar: action,
          },
        }
      })
    },
  };
};
