import { Container } from "modules";
import { Fields, Button } from "components";
import { FastField } from "formik";
import cx from "classnames";

import { useHooks } from "hooks";
import useStore from "store";

const Login = () => {
  const { get } = useHooks();
  const { signIn, system } = useStore((state) => state);

  const classNames = cx(
    "h-[100vh] px-[6%] py-[12%] login-wrapper ",
    system.layoutColor == "black" &&
    "bg-[url('../../assets/images/loginframe-black.svg')]",
    system.layoutColor == "red" &&
    "bg-[url('../../assets/images/loginframe-red.svg')]",
    system.layoutColor == "blue" &&
    "bg-[url('../../assets/images/loginframe-blue.svg')]",
    system.layoutColor == "green" &&
    "bg-[url('../../assets/images/loginframe-green.svg')]"
  );

  const title = cx(
    system.layoutColor == "black" && "text-[#000]",
    system.layoutColor == "red" && "text-[#92082F]",
    system.layoutColor == "blue" && "text-[#044E7E]",
    system.layoutColor == "green" && "text-[#005041]"
  );

  return (
    <section className={classNames}>
      <Container.Form
        className="xl:max-w-[650px] lg:max-w-[450px] md:max-w-[400px] md:flex md:justify-center"
        url="users/login"
        method="post"
        fields={[
          {
            name: "login",
            type: "string",
            // required: true,
          },
          {
            name: "password",
            type: "string",
          },
        ]}
        onSuccess={(response) => {
          signIn({
            token: get(response, "data.token"),
            data: get(response, "data.user"),
          });
        }}
        onError={(error) => {
          console.log("Error", error);
        }}
      >
        {({ isLoading, setFieldTouched }) => {
          return (
            <div>
              <div>
                <h1 className="text-center text-[#000000DE] text-[32px] font-[600] mb-[8px]">
                  Welcome to <span className={title}>ERP</span>
                </h1>
                <p className="text-center text-[20px] text-[#9EA3B5] mb-[48px]">
                  Please enter your admin credentials
                </p>
                <div></div>
                <FastField
                  component={Fields.Input}
                  placeholder="Login"
                  name="login"
                  setFieldTouched={setFieldTouched}
                  rootClassName="mb-7"
                  isLoginPage={true}
                />
                <FastField
                  name="password"
                  component={Fields.Password}
                  // errorMessage="Password"
                  placeholder={"Password"}
                  type="password"
                  rootClassName="mb-7"
                />
                <Button
                  title={isLoading ? "Please wait a second" : "Log in"}
                  isLoading={isLoading}
                  disabled={isLoading}
                  size="large"
                  htmlType="submit"
                  className="w-full h-auto"
                />
              </div>
              <div></div>
            </div>
          );
        }}
      </Container.Form>
    </section>
  );
};

export default Login;
