import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import useStore from "store";
import { storage, helpers } from "services";
import Logo from "assets/images/icons/erp.png";
import { useHooks } from "hooks";
import { gen4 } from "services/helpers";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}


const Navigation2: React.FC = () => {
  const { get, t, location, qs } = useHooks()
  const { logOut, changeTheme, system, auth } = useStore((state) => state);
  const [openKeys, setOpenKeys] = useState<string[]>(["sub1"]);
  const [isDarkMode, setIsDarkMode] = useState(storage.get("theme") == "light" ? false : true);

  const data = get(auth, "data")

  const pathSegments = location.pathname.split('/');
  const pathname = `/${pathSegments[1]}`;

  // const onOpenChange = (keys: string[]) => {
  //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
  //   if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  // document.body.classList.add(isDarkMode ? "dark" : "light");

  const changeThemeFunc = () => {
    setIsDarkMode((prevMode) => !prevMode);
    changeTheme(isDarkMode ? "light" : "dark");
    storage.set("theme", isDarkMode ? "light" : "dark");
    if (!isDarkMode) {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  };

  const classNames = cx(
    "h-full bg-no-repeat bg-cover bg-left	",
    system.layoutColor == "black" && "bg-[#000]",
    system.layoutColor == "red" && "bg-[#92082F]",
    system.layoutColor == "blue" && "bg-[#0104a0]",
    system.layoutColor == "green" && "bg-[#005041]",
    system.layoutColor == "orange" && "bg-[#e25012]",
    system.layoutSide == "left" && "vertical-menu__left",
    system.layoutSide == "right" && "vertical-menu__right",
    system.layoutPattern == "pattern1" &&
    "bg-[url('../../assets/images/nav-shape.svg')]",
    system.layoutPattern == "pattern2" &&
    "bg-[url('../../assets/images/nav-shape2.svg')]",
    system.layoutPattern == "pattern3" &&
    "bg-[url('../../assets/images/nav-shape3.svg')]",
    system.layoutPattern == "pattern4" &&
    "bg-[url('../../assets/images/nav-shape4.svg')]"
  );

  const modes = cx(
    system.layoutSide == "left" && "inline",
    system.layoutSide == "right" && "inline",
    system.layoutSide == "top" && "horizontal",
    system.layoutSide == "bottom" && "horizontal"
  );

  const filterKeys = get(data, "role.access", []).map((item: any) => (item.value));

  const filterMenuItems = (menuItems: MenuItem[], filterKeys: string[]): MenuItem[] => {
    return menuItems.reduce((filteredItems: MenuItem[], item) => {
      if (filterKeys.includes(item.key)) {
        filteredItems.push(item);
      } else if (item.children) {
        const filteredChildren = item.children.filter(child => filterKeys.includes(child.key));
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, []);
  };

  return (
    <div className={classNames}>
      <div className="flex justify-center text-center cursor-pointer pt-[30px] pb-[30px] text-[#9EA3B5]">
        <Link to="/">
          <img className="w-[50px]" src={Logo} alt="logo" />
        </Link>
      </div>
      <div className={"h-[80vh] overflow-y-scroll no-scrollbar"}>
        {filterMenuItems(helpers.menuItems, filterKeys).map((menuItem, i) => (
          <React.Fragment key={menuItem.key + i}>
            {/* @ts-ignore */}
            <Link to={get(menuItem, "route")} className={pathname === menuItem.route ? "navbar-menuitem navbar-menuitem-actived" : "navbar-menuitem"}>
              <div>
                {menuItem.icon}
              </div>
              {/* @ts-ignore */}
              <p>{t(get(menuItem, "label"))}</p>
            </Link>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Navigation2;
