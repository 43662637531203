import NotfoundBlack from "../../assets/images/notfound-black.svg";
import NotfoundRed from "../../assets/images/notfound-red.svg";
import NotfoundBlue from "../../assets/images/notfound-blue.svg";
import NotfoundGreen from "../../assets/images/notfound-green.svg";

import Button from "components/button";
import { useHooks } from "hooks";
import useStore from "store";

const NotFound = () => {
  const { navigate } = useHooks();
  const { system } = useStore((state) => state);

  return (
    <div>
      <div className="flex align-center items-center flex-col justify-between">
        {system.layoutColor == "black" ? (
          <img
            className="xl:max-w-[650px] lg:max-w-[450px] md:max-w-[400px] mt-[100px]"
            src={NotfoundBlack}
            alt="not-found"
          />
        ) : system.layoutColor == "red" ? (
          <img
            className="xl:max-w-[650px] lg:max-w-[450px] md:max-w-[400px] mt-[100px]"
            src={NotfoundRed}
            alt="not-found"
          />
        ) : system.layoutColor == "green" ? (
          <img
            className="xl:max-w-[650px] lg:max-w-[450px] md:max-w-[400px] mt-[100px]"
            src={NotfoundGreen}
            alt="not-found"
          />
        ) : (
          <img
            className="xl:max-w-[650px] lg:max-w-[450px] md:max-w-[400px] mt-[100px]"
            src={NotfoundBlue}
            alt="not-found"
          />
        )}
        <Button
          title="Back Home"
          size="large"
          onClick={() => navigate("/")}
          className="px-[28px] py-[22px] mt-[40px]"
        />
      </div>
    </div>
  );
};

export default NotFound;
