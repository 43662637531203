import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import cx from "classnames";

import { Navigation, Header, Navigation2 } from "components";
import useStore from "store";

const { Sider, Content } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { system } = useStore();

  const layoutClass = cx(
    system.layoutSide == "left" && "",
    system.layoutSide == "right" && "",
    system.layoutSide == "top" && "flex align-center",
    system.layoutSide == "bottom" && "flex align-center"
  );

  return (
    <Layout className="h-full">
      {system.layoutSide == "left" && (
        <Sider
          trigger={null}
        // collapsible
        // collapsed={collapsed}
        // onMouseEnter={() => setCollapsed(false)}
        // onMouseLeave={() => setCollapsed(true)}
        >
          <Navigation2 />
        </Sider>
      )}
      <Layout className={layoutClass}>
        <Header />
        {/* <Header {...{ collapsed, setCollapsed }} /> */}
        {system.layoutSide == "top" && <Navigation2 />}
        <Content className="overflow-auto p-[16px] min-h-[280px]">
          <Outlet />
        </Content>
        {system.layoutSide == "bottom" && <Navigation2 />}
      </Layout>
      {system.layoutSide == "right" && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onMouseEnter={() => setCollapsed(false)}
          onMouseLeave={() => setCollapsed(true)}
        >
          <Navigation />
        </Sider>
      )}
    </Layout>
  );
};

export default App;
