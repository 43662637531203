import { Layout, Select, Tooltip } from "antd";
import { ProductOutlined } from '@ant-design/icons';
import cx from "classnames";
import { Link } from "react-router-dom";
import i18next from "i18next";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import config from "config";
import { useHooks } from "hooks";
import useStore from "store";
import { privateRoutes } from "routes/data";
import Logo from "assets/images/icons/logo.png";
import Avatar from 'assets/images/27470334_7309681.jpg'
import Arrow from 'assets/images/dropdown-arrow.svg'
import PulIcon from 'assets/images/icons/kirimIcon.png'
import TovarIcon from 'assets/images/icons/tovarkirim.png'


import './style.scss'

const { Header } = Layout;

// interface IProps {
//   collapsed: boolean;
//   setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
// }

// const HeaderComponent = ({ collapsed, setCollapsed }: IProps) => {
const HeaderComponent = () => {
  const { get, location, t, navigate } = useHooks();
  const {
    changeLayoutSide,
    changeLayoutColor,
    changePattern,
    system,
    logOut
  } = useStore();
  const { Option } = Select;



  const classNames = cx(
    "flex justify-between items-center p-0",
    system.layoutColor == "black" && "bg-[#000]",
    system.layoutColor == "red" && "bg-[#92082F]",
    system.layoutColor == "blue" && "bg-[#0104a0]",
    system.layoutColor == "green" && "bg-[#006351]",
    system.layoutColor == "orange" && "bg-[#e25012]",
    system.layoutPattern == "pattern1" &&
    "bg-[url('../../assets/images/nav-shape.svg')]",
    system.layoutPattern == "pattern2" &&
    "bg-[url('../../assets/images/nav-shape2.svg')]",
    system.layoutPattern == "pattern3" &&
    "bg-[url('../../assets/images/nav-shape3.svg')]",
    system.layoutPattern == "pattern4" &&
    "bg-[url('../../assets/images/nav-shape4.svg')]"
  );

  const fastBtns = cx(
    system.layoutColor == "black" && "bg-[#000]",
    system.layoutColor == "red" && "bg-[#92082F]",
    system.layoutColor == "blue" && "bg-[#0104a0]",
    system.layoutColor == "green" && "bg-[#006351]",
    system.layoutColor == "orange" && "bg-[#e25012]",
  );

  const changeLang = (langCode: string) => {
    i18next.changeLanguage(langCode);
  };

  const menus = privateRoutes.find((m) => m.path === get(location, "pathname"));

  return (
    <Header className={classNames}>
      {(system.layoutSide == "top" || system.layoutSide == "bottom") && (
        <div className="flex justify-center text-center text-[20px] font-[500] cursor-pointer text-[#9EA3B5] ml-[40px]">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
      )}
      <div className="relative">
        {/* {system.layoutSide == "left" && (
          <div
            onClick={() => setCollapsed(!collapsed)}
            className="sidebarBtn top-[17px] left-[7px]"
          >
            {collapsed ? <SidebarIconClose /> : <SidebarIconOpen />}
          </div>
        )} */}
        <span className="font-[500] text-[20px] text-[#fff] ml-[48px]">
          {menus?.title}
        </span>
      </div>
      <div className="flex items-center">
        <div className="flex mr-[50px]">
          <div className={fastBtns + " flex items-center p-[7px] pb-[9px] pr-[10px] rounded-[30px] h-[40px] mr-[30px]"}>
            <AttachMoneyIcon className="text-[#fff]" fontSize="large" />
            <Tooltip title={t("Pul kirim qilish")}>
              <div className="cursor-pointer" onClick={() => navigate("/money-income")}>
                <AddCircleOutlineOutlinedIcon className="text-[#fff] mr-[5px]" />
              </div>
            </Tooltip>
            <Tooltip title={t("Pul chiqim qilish")}>
              <div className="cursor-pointer" onClick={() => navigate("/money-expense")}>
                <RemoveCircleOutlineOutlinedIcon className="text-[#fff]" />
              </div>
            </Tooltip>
          </div>
          <div className={fastBtns + " flex items-center p-[7px] pb-[9px] pr-[10px] pl-[10px] rounded-[30px] h-[40px]"}>
            <ProductOutlined className="text-[#fff] mr-[10px]" style={{ fontSize: "30px" }} />
            <Tooltip title={t("Tovar kirim qilish")}>
              <div className="cursor-pointer" onClick={() => navigate("/product-income")}>
                <AddCircleOutlineOutlinedIcon className="text-[#fff] mr-[5px]" />
              </div>
            </Tooltip>
            <Tooltip title={t("Tovar chiqim qilish")}>
              <div className="cursor-pointer" onClick={() => navigate("/product-expense")}>
                <RemoveCircleOutlineOutlinedIcon className="text-[#fff]" />
              </div>
            </Tooltip>
          </div>
        </div>
        <Select
          defaultValue={system?.lang}
          size={"large"}
          onChange={(value: any) => {
            changeLang(value);
          }}
        >
          {config.API_LANGUAGES.map((lang) => (
            <Option key={lang.code} value={lang?.code}>{get(lang, 'short', '')}</Option>
          ))}
        </Select>
        <div className="profile-dropdown">
          <div className="profile-dropdown__circle">
            <img className="profile-dropdown__avatar" src={Avatar} alt="avatar" />
            <img className="profile-dropdown__arrow" src={Arrow} alt="arrow" />
          </div>
          <div className="profile-dropdown__options">
            <p className="profile-dropdown__item profile-dropdown__info">Admin</p>
            <Link className="profile-dropdown__item profile-dropdown__link" to="/profile">{t("Profile")}</Link>
            <p className="profile-dropdown__item profile-dropdown__link" onClick={() => (
              logOut(),
              navigate("/")
            )}>
              {t("Log out")}
            </p>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
