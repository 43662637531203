// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.excel-btn {
  background-color: #4caf50;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.excel-btn:hover {
  opacity: 0.8;
}

.excel-btn button {
  background-color: transparent;
  padding: 5;
  color: #fff;
  border: none;
  cursor: pointer;
}

.excel-btn img {
  width: 30px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/excelBtn/style.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AACD;AACC;EACC,YAAA;AACF;;AAGA;EACC,6BAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAAD;;AAGA;EACC,WAAA;EACA,YAAA;AAAD","sourcesContent":[".excel-btn {\n\tbackground-color: #4caf50;\n\tdisplay: flex;\n\talign-items: center;\n\tborder-radius: 5px;\n\tcursor: pointer;\n\ttransition: all ease 0.3s;\n\n\t&:hover {\n\t\topacity: 0.8;\n\t}\n}\n\n.excel-btn button {\n\tbackground-color: transparent;\n\tpadding: 5;\n\tcolor: #fff;\n\tborder: none;\n\tcursor: pointer;\n}\n\n.excel-btn img {\n\twidth: 30px;\n\theight: 30px;\n\t//margin-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
