import Input from "./input";
import InputMask from "./inputMask";
import InputMaskSum from "./inputMaskSum";
import Password from "./password";
import Select from "./select";
import SelectD from "./selectD";
import AsyncSelect from "./ant-async-select";
import AsyncSelectD from "./asyncSelectD";
import DatePicker from "./datePicker";
import FileUploadEmpower from "./fileUploadEmpower";
import FileUpload from "./fileUpload";
import Textarea from "./textarea";
import Radio from "./radio";
import TreeSelect from "./treeSelect";

import {
  AntInput,
  AntTextarea,
  AntSelect,
  AntTimePicker,
  AntPassword,
  AntTreeSelect,
  AntInputNumber,
  AntRadio,
} from "./AntFields";

export default {
  Input, InputMask, InputMaskSum, Password, Select, SelectD, TreeSelect, AsyncSelect, AsyncSelectD, DatePicker, FileUpload, FileUploadEmpower, Textarea, AntInput, Radio,
  AntTextarea,
  AntSelect,
  AntTimePicker,
  AntPassword,
  AntTreeSelect,
  AntInputNumber,
  AntRadio,
};