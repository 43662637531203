import excelIcon from 'assets/images/icons/excel-icon.svg';
import ReactHTMLTableToExcel from "react-html-table-to-excel"

import './style.scss'
import { useHooks } from 'hooks';

function ExcelBtn({ className, table, filename }) {
	const { t } = useHooks()
	return (
		// <a href={`${config.API_ROOT}/reports/report/salary-all?month=${month}`} target={"_blank"} className={`excel-table ${className}`}>
		<div className={className + " excel-btn px-[7px] py-[3px]"}>
			<img src={excelIcon} alt="" width="50px" height="50px" />
			<ReactHTMLTableToExcel
				id="test-table-xls-button"
				className="download-table-xls-button"
				table={table}
				filename={filename}
				sheet="tablexls"
				buttonText={t("EXCEL formatida")}
			/>
		</div>
		// </a>
	);
}

export default ExcelBtn;
