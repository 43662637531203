import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import useStore from "store";
import { storage, helpers } from "services";
import Logo from "assets/images/icons/logo.png";
import { useHooks } from "hooks";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}


const Navigation: React.FC = () => {
  const { get } = useHooks()
  const { logOut, changeTheme, system, auth } = useStore((state) => state);
  const [openKeys, setOpenKeys] = useState<string[]>(["sub1"]);
  const [isDarkMode, setIsDarkMode] = useState(storage.get("theme") == "light" ? false : true);

  const data = get(auth, "data")

  // const onOpenChange = (keys: string[]) => {
  //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
  //   if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  // document.body.classList.add(isDarkMode ? "dark" : "light");

  const changeThemeFunc = () => {
    setIsDarkMode((prevMode) => !prevMode);
    changeTheme(isDarkMode ? "light" : "dark");
    storage.set("theme", isDarkMode ? "light" : "dark");
    if (!isDarkMode) {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  };

  const classNames = cx(
    "h-full",
    system.layoutColor == "black" && "bg-[#000]",
    system.layoutColor == "red" && "bg-[#92082F]",
    system.layoutColor == "blue" && "bg-[#0104a0]",
    system.layoutColor == "green" && "bg-[#005041]",
    system.layoutColor == "orange" && "bg-[#e25012]",
    system.layoutSide == "left" && "vertical-menu__left",
    system.layoutSide == "right" && "vertical-menu__right",
    system.layoutPattern == "pattern1" &&
    "bg-[url('../../assets/images/nav-shape.svg')]",
    system.layoutPattern == "pattern2" &&
    "bg-[url('../../assets/images/nav-shape2.svg')]",
    system.layoutPattern == "pattern3" &&
    "bg-[url('../../assets/images/nav-shape3.svg')]",
    system.layoutPattern == "pattern4" &&
    "bg-[url('../../assets/images/nav-shape4.svg')]"
  );

  const modes = cx(
    system.layoutSide == "left" && "inline",
    system.layoutSide == "right" && "inline",
    system.layoutSide == "top" && "horizontal",
    system.layoutSide == "bottom" && "horizontal"
  );

  const filterKeys = get(data, "role.access", []).map((item: any) => (item.value));

  const filterMenuItems = (menuItems: MenuItem[], filterKeys: string[]): MenuItem[] => {
    return menuItems.reduce((filteredItems: MenuItem[], item) => {
      if (filterKeys.includes(item.key)) {
        filteredItems.push(item);
      } else if (item.children) {
        const filteredChildren = item.children.filter(child => filterKeys.includes(child.key));
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, []);
  };

  return (
    <div className={classNames + " overflow-y-scroll overflow-x-hidden no-scrollbar"}>
      <Menu
        //@ts-ignore
        mode={modes}
        openKeys={openKeys}
        // onOpenChange={onOpenChange}
        className={classNames}
      >
        {/* <div> */}
        <div className="flex justify-center text-center text-[20px] font-[500] mt-[30px] mb-[60px] cursor-pointer text-[#9EA3B5]">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        {filterMenuItems(helpers.menuItems, filterKeys).map((menuItem, i) => (
          <React.Fragment key={menuItem.key + i}>
            {menuItem.children ? (
              <Menu.SubMenu
                key={menuItem.key + i}
                icon={menuItem.icon}
                title={menuItem.label}
              >
                {menuItem.children.map((childItem: any) => (
                  <Menu.Item key={childItem.key} className="text-[#fff]">
                    {childItem.route ? (
                      <Link to={childItem.route}>{childItem.label}</Link>
                    ) : (
                      <>{childItem.route}</>
                    )}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item
                key={menuItem.key}
                className="text-[#fff]"
                icon={menuItem.icon}
              >
                {menuItem.route ? (
                  <Link to={menuItem.route}>{menuItem.label}</Link>
                ) : (
                  <>{menuItem.route}</>
                )}
              </Menu.Item>
            )}
          </React.Fragment>
        ))}
        {/* </div> */}
        {/* <div>
        <div className="absolute bottom-[36px] left-[20%]">
          <div className="flex justify-center mb-[60px]">
            <div className="profile-panel mt-[100px] transition-all ease-in-out duration-300">
              <img
                className="sider-avatar cursor-pointer w-[105px] relative z-1 h-[95px] rounded-[12px]"
                src={Avatar}
                alt="user-avatar"
                onClick={() => {
                  navigate("/profile");
                }}
              />
              <div className="username-input w-[175px] relative z-10 px-[6px] py-[4px] rounded-[16px] bg-[#E6ECFE] flex justify-between items-center">
                <Link
                  to="/profile"
                  className="inline-block text-[12px] py-[14px] px-[8px] transition rounded-[8px] hover:bg-[#DADFF1] hover:text-[#000]"
                >
                  Username
                </Link>
                <div
                  className="log-out cursor-pointer transition-all ease-in-out duration-300  p-[4px] hover:bg-[#DADFF1] rounded-[10px]"
                  onClick={() => {
                    logOut();
                    storage.remove("token");
                    navigate("/");
                  }}
                >
                  <LogOut />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <span className="text-[#9EA3B5] text-[16px]">Light</span>
            <label className="relative inline-block w-[70px] h-[34px] mx-[15px]">
              <input
                type="checkbox"
                onChange={changeThemeFunc}
                checked={isDarkMode}
                className="w-0 h-0 opacity-0"
              />
              <span className="slider round">
                <div className="dark-icon absolute top-[6px] left-[7px]">
                  <Dark />
                </div>
                <div className="light-icon absolute top-[6px] right-[7px]">
                  <Light />
                </div>
              </span>
            </label>
            <span className="text-[#9EA3B5] text-[16px]">Dark</span>
          </div>
        </div>
      </div> */}
      </Menu>
    </div>
  );
};
{
  /* <Dropdown
  className="mr-5"
  menu={{
    items,
    onClick: (value) => {
      if (get(value, "key") === "4") {
        logOut();
        storage.remove("token");
        navigate("/");
      }
    },
  }}
>
  <Avatar size={35} icon={<UserOutlined width={"20px"} />} />
</Dropdown>; */
}
export default Navigation;
