import { lazy } from "react";

const Dashboard = lazy(() => import("pages/dashboard"));
const Localization = lazy(() => import("pages/localizationPanel"));
const Employees = lazy(() => import("pages/control/emoloyees"));
const Clients = lazy(() => import("pages/control/clients"));
const EmployeesUpdate = lazy(() => import("pages/control/emoloyees/update"));
const AddExp = lazy(() => import("pages/accounting/additionalExp"));
const Settings = lazy(() => import("pages/settings/settings"));
const Profile = lazy(() => import("pages/admin"));
const Warehouses = lazy(() => import("pages/warehouses"));
const OnRoad = lazy(() => import("pages/warehouses/warehouse/onRoad"));
const Warehouse = lazy(() => import("pages/warehouses/warehouse"));
const TransfersHistory = lazy(() => import("pages/warehouses/warehouse/transfersHistory"));
const Income = lazy(() => import("pages/accounting/income"));
const Expense = lazy(() => import("pages/accounting/expense"));
const MoneyIncome = lazy(() => import("pages/accounting/accounting/moneyIncome"));
const MoneyIncomeUpdate = lazy(() => import("pages/accounting/accounting/moneyIncomeUpdate"));
const MoneyExpense = lazy(() => import("pages/accounting/accounting/moneyExpense"));
const MoneyExpenseUpdate = lazy(() => import("pages/accounting/accounting/moneyExpenseUpdate"));
const ProductIncome = lazy(() => import("pages/accounting/income/update"));
const ProductExpense = lazy(() => import("pages/accounting/expense/update"));
const SmallExpense = lazy(() => import("pages/accounting/smallExpense"));
const Party = lazy(() => import("pages/accounting/partyInner"));
const Accounting = lazy(() => import("pages/accounting/accounting"));
const AccHistory = lazy(() => import("pages/accounting/accHistory"));
const TranslateHistory = lazy(() => import("pages/accounting/accounting/translateHistory"));
const Products = lazy(() => import("pages/products"));
const NotFound = lazy(() => import("pages/notFound"));
const InitialBalance = lazy(() => import("pages/warehouses/warehouse/initialProducts"));
const Reports = lazy(() => import("pages/reports"));
const ReportCreate = lazy(() => import("pages/reports/report"));

export interface IRoute {
  path: string;
  key?: string | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/employees",
    key: "employees",
    title: "Сотрудники",
    element: <Employees />,
  },
  {
    path: "/employee/update:id",
    key: "employees",
    title: "Изменить сотрудника",
    element: <EmployeesUpdate />,
  },
  {
    path: "/settings",
    key: "inner-settings",
    title: "Настройки",
    element: <Settings />,
  },
  {
    path: "/profile",
    key: "profile",
    title: "Профил",
    element: <Profile />,
  },
  {
    path: "/warehouses",
    key: "warehouses",
    title: "Склады",
    element: <Warehouses />,
  },
  {
    path: "/income/:id",
    key: "warehouses",
    title: "Партия",
    element: <Party />,
  },
  {
    path: "/warehouses/:id",
    key: "warehouses",
    title: "Склад",
    element: <Warehouse />,
  },
  {
    path: "/on-road",
    key: "warehouses",
    title: "Yo'ldagi partiyalar",
    element: <OnRoad />,
  },
  {
    path: "/clients",
    key: "clients",
    title: "Kontragentlar",
    element: <Accounting />,
  },
  {
    path: "/reports",
    key: "reports",
    title: "Hisobot",
    element: <Reports />,
  },
  {
    path: "/report-create",
    key: "reports",
    title: "Hisobot yaratish",
    element: <ReportCreate />,
  },
  {
    path: "/income",
    key: "income",
    title: "Tovar kirimi",
    element: <Income />,
  },
  {
    path: "/expense",
    key: "expense",
    title: "Tovar chiqimlari ro'yxati",
    element: <Expense />,
  },
  {
    path: "/money-expense",
    key: "clients",
    title: "Pul chiqimi",
    element: <MoneyExpense />,
  },
  {
    path: "/money-expense/:id",
    key: "clients",
    title: "Pul chiqimi",
    element: <MoneyExpense />,
  },
  {
    path: "/money-expense/update/:id",
    key: "clients",
    title: "Pul chiqimini o'zgartirish",
    element: <MoneyExpenseUpdate />,
  },
  {
    path: "/money-income",
    key: "clients",
    title: "Pul kirimi",
    element: <MoneyIncome />,
  },
  {
    path: "/money-income/:id",
    key: "clients",
    title: "Pul kirimi",
    element: <MoneyIncome />,
  },
  {
    path: "/money-income/update/:id",
    key: "clients",
    title: "Pul kirimini o'zgartirish",
    element: <MoneyIncomeUpdate />,
  },
  {
    path: "/product-income",
    key: "income",
    title: "Tovar kirim qilish",
    element: <ProductIncome />,
  },
  {
    path: "/product-income/:id",
    key: "income",
    title: "Tovar kirimini o'zgartirish",
    element: <ProductIncome />,
  },
  {
    path: "/product-expense",
    key: "expense",
    title: "Tovar chiqim qilish",
    element: <ProductExpense />,
  },
  {
    path: "/product-expense/:id",
    key: "expense",
    title: "Tovar chiqimini o'zgartirish",
    element: <ProductExpense />,
  },
  {
    path: "/clients/:id",
    key: "clients",
    title: "Kontragent",
    element: <Clients />,
  },
  {
    path: "/small-expense",
    key: "small-expense",
    title: "Мелкие расходы",
    element: <SmallExpense />,
  },
  {
    path: "/products",
    key: "products",
    title: "Продукты",
    element: <Products />,
  },
  {
    path: "/accounting-history",
    key: "acc-history",
    title: "Pul kirim-chiqim tarixi",
    element: <AccHistory />,
  },
  {
    path: "/additional-expenses",
    key: "additional-expenses",
    title: "Dop rasxodlar",
    element: <AddExp />,
  },
  {
    path: "/transfers-history",
    key: "warehouses",
    title: "Transferlar tarixi",
    element: <TransfersHistory />,
  },
  {
    path: "/translate-history",
    key: "translate-history",
    title: "O'tkazmalar tarixi",
    element: <TranslateHistory />,
  },
  {
    path: "/initial-balance",
    key: "warehouses",
    title: "Boshlang'ich qoldiq",
    element: <InitialBalance />,
  },
  {
    path: "/",
    key: "dashboard",
    title: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/localization",
    key: "inner-settings",
    title: "Tillarni o'zgartirish paneli",
    element: <Localization />,
  },
  {
    path: "*",
    key: "*",
    title: "",
    element: <NotFound />,
  },
  // {
  //   path: "/localization",
  //   access: ["admin"],
  //   title: '',
  //   element: <LocalizationPanel />,
  // },
];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   element: <Login />,
  //   key: "inner-settings",
  //   title: "Welcome",
  // },
];

export { privateRoutes, publicRoutes };
