import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import PlaylistAddCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCircleOutlined';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
// import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import Onway from 'assets/images/icons/delivery.png'
import Customs from 'assets/images/icons/custom-clearance.png'
import Box from 'assets/images/icons/box.png'

const getClienType = (i: number) => {
  switch (i) {
    case 1:
      return "Клиент";
    case 2:
      return "Поставщик";
    case 3:
      return "Логист";
    case 4:
      return "Таможня";
    default:
      return "Сотрудник"
  }
};

const clienTypeArr = [
  { value: 1, label: "Клиент" },
  { value: 2, label: "Поставщик" },
  { value: 3, label: "Логист" },
  { value: 4, label: "Таможня" }
]

const getProductStatus = (i: number) => {
  switch (i) {
    case 1:
      return "В пути";
    case 2:
      return "На таможне-im70";
    case 3:
      return "На таможне-im74";
    case 4:
      return "На таможне-im40";
    case 5:
      return "Доставлено";
    default:
      return "-"
  }
};

const getCashType = (i: number) => {
  switch (i) {
    case 1:
      return "Перечисления";
    case 2:
      return "Терминал";
    case 3:
      return "Наличный";
    default:
      return "-"
  }
};

const getProductStatusImage = (i: number) => {
  switch (i) {
    case 1:
      return Onway;
    case 2:
      return Customs;
    case 3:
      return Customs;
    case 4:
      return Customs;
    case 5:
      return Box;
    default:
      return ""
  }
};

const productStatusArr = [
  { value: 1, label: "В пути" },
  { value: 2, label: "На таможне-im70" },
  { value: 3, label: "На таможне-im74" },
  { value: 4, label: "На таможне-im40" },
  { value: 5, label: "Доставлено" }
]

const cashTypeArr = [
  { value: 1, label: "Перечисления" },
  { value: 2, label: "Терминал" },
  { value: 3, label: "Наличный" }
]

const getCurrencyType = (i: number) => {
  switch (i) {
    case 1:
      return "Ko'cha narxi";
    case 2:
      return "Markaziy bank";
    default:
      return "-"
  }
};

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}

const menuItems: MenuItem[] = [
  { key: "dashboard", label: "Dashboard", icon: <DashboardOutlinedIcon />, route: "/" },
  { key: "employees", label: "Ishchilar", icon: <Person3OutlinedIcon />, route: "/employees" },
  { key: "clients", label: "Kontragenlar", icon: <PeopleAltOutlinedIcon />, route: "/clients" },
  { key: "acc-history", label: "Pul kirim-chiqim tarixi", icon: <AccountBalanceWalletOutlinedIcon />, route: "/accounting-history" },
  // { key: "translate-history", label: "Pul o'tkazmalari tarixi", icon: <MoveDownOutlinedIcon />, route: "/translate-history" },
  { key: "income", label: "Tovar kirim", icon: <ArchiveOutlinedIcon />, route: "/income" },
  { key: "expense", label: "Tovar chiqim", icon: <UnarchiveOutlinedIcon />, route: "/expense" },
  { key: "additional-expenses", label: "Dop rasxodlar", icon: <PlaylistAddCircleOutlinedIcon />, route: "/additional-expenses" },
  { key: "small-expense", label: "Mayda chiqimlar", icon: <DoNotDisturbOnOutlinedIcon />, route: "/small-expense" },
  // { key: "initial-balance", label: "Boshlang'ich qoldiqlar", icon: <DatasetLinkedOutlinedIcon />, route: "/initial-balance" },
  { key: "reports", label: "Hisobot", icon: <SummarizeOutlinedIcon />, route: "/reports" },
  { key: "warehouses", label: "Omborlar", icon: <WarehouseOutlinedIcon />, route: "/warehouses" },
  { key: "party-in-road", label: "Yo'ldagi partiyalar", icon: <LocalShippingOutlinedIcon />, route: "/on-road" },
  // { key: "products", label: "Produktlar", icon: <CategoryOutlinedIcon />, route: "/products", },
  { key: "inner-settings", label: "Sozlamalar", icon: <SettingsApplicationsOutlinedIcon />, route: "/settings" },
];

function gen4() {
  return Math.random()
    .toString(16)
    .slice(-4);
}

export default {
  getClienType,
  clienTypeArr,
  getProductStatus,
  getProductStatusImage,
  productStatusArr,
  menuItems,
  cashTypeArr,
  getCashType,
  getCurrencyType
}
export {
  gen4
}